@import '../../../scss/import';

.odt-FavoritesListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 12px;
    border-bottom: 1px solid rgba($color-text-black-disabled, 0.2);

    &.is-loading {
        .odt-FavoritesListItem__title,
        .odt-FavoritesListItem__link {
            color: $color-text-black-disabled;
            pointer-events: none;
            cursor: default;
        }
        .odt-FavoritesListItem__remove {
            animation: loading 800ms infinite ease-in-out;
        }
    }

    &__title {
        @include dmBrandMedium;
        font-size: $typo-font-size-body1;
        color: $color-text-black-emphasis-medium;
    }

    &__link {
        flex: 1;
        padding-right: 1em;
        color: $color-text-black-emphasis-medium;
        hyphens: auto;

        &:hover {
            @media(hover: hover) {
                color: $color-text-black-emphasis-high;
            }
        }
    }

    &__remove {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        width: 16px;
        height: 16px;
        font-size: 10px;
        color: white;

        border-radius: 100%;
        background-color: $color-text-black-emphasis-medium;
        outline: none;
        cursor: pointer;

        &:hover {
            @media(hover: hover) {
                background-color: $color-text-black-emphasis-high;
            }
        }

        &[disabled] {
            background-color: $color-text-black-disabled;
        }
    }
}

@keyframes loading {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}