@import '../../../scss/import';

.odt-Glossary {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 60px;
  width: 100%;
  height: auto;

  @media screen and (min-width: $breakpoint-desktop) {
    top: 60px;
    bottom: 0;
  }

}