.odt-FeedbackSteps {
    label {
        display: block;
        margin-bottom: .5em;
    }

    input[type="checkbox"] {
        margin-right: .35em;
    }

    textarea {
        margin-top: 2em;
        background-color: #f7f7f7;
        outline: none;
    }
}