@import '../../../scss/import';

.odt-LoginForm {
    padding: 24px;
    height: 100%;
    border-radius: 4px;
    background-color: $color-surface-light;

    transform: translateY(100%);

    transition: transform 250ms ease-in-out;

    &.is-visible {
        transform: translateY(0);
    }

    > *:last-child {
        margin-top: 40px;
    }

    .Form-control {
        padding: 4px;
        line-height: 1;
        border-bottom: 1px solid black;
    }
}