.odt-LayoutContainer {
    margin: 0 auto;
    max-width: 100%;

    &.has-padding {
        padding: 0 16px;
    }

    &--extraSmall {
        max-width: 580px;
    }
    &--small {
        max-width: 768px;
    }
    &--medium {
        max-width: 1024px;
    }
    &--large {
        max-width: 1280px;
    }
}