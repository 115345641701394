@import '../../../scss/import';

.odt-FeedbackHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (min-width: $breakpoint-sm) {
        flex-wrap: nowrap;
    }

    &__title {
        flex: 1 0 auto;
        margin: 1em 0;

        @media screen and (min-width: $breakpoint-sm) {
            flex: 1;
        }
    }
}