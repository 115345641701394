@import '../../../scss/import';

.odt-Paragraph {
    &--body1 {
        @include dmBrandRegular;
        font-size: $typo-font-size-body1;
        line-height: 1.625;
        letter-spacing: .5px;
        color: $color-text-black-emphasis-high;
    }

    &--body2 {
        @include dmSupportRegular;
        font-size: $typo-font-size-body2;
        line-height: 1.43;
        letter-spacing: .25px;
        color: $color-text-black-emphasis-medium;
    }

    &--center {
        text-align: center;
    }
}