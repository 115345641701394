@import '../../../scss/import';

.GlossaryListSection {
    &:not(:last-child) {
        margin-bottom: 1em;
    }

    &-title {
        @include dmBrandMedium;
        line-height: 0.75;
        margin: 0 0 0.25em 0;

        @media screen and (min-width: $breakpoint-md) {
            font-size: 48px;
        }
    }

    &-items {
        display: block;
        width: 100%;
        margin: 0;
        overflow: hidden;

        // For browsers without grid support
        column-count: 3;
        column-gap: 20px;

        @supports (display: grid) {
            column-count: unset;
            column-gap: unset;

            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-column-gap: 20px;
            justify-items: stretch;
            align-items: stretch;
        }
        @media screen and (min-width: $breakpoint-md) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}