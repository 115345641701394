@import '../../../scss/import';

.GlossaryList {
    position: relative;
    margin: 0;
    max-height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &.locked {
        position: absolute;
        overflow: hidden !important;
        top: 0;
        pointer-events: none;
        visibility: hidden;
    }

    &-content {
        padding: 16px;
        overflow: hidden;

        @media screen and (min-width: $breakpoint-md) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }
}