@import '../../../scss/import';

.odt-Login {
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 100vh;

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: 0 20px;
        max-width: 100%;
        min-height: 100vh; // for IE

        @media screen and (min-width: $breakpoint-md) {
            max-width: 400px;
        }
    }

    &__header,
    &__body {
        flex: 1 1 50%;
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin: 0;
        padding: 60px 0 0 0;
    }

    &__body {
        overflow: hidden;
    }

    &__logo {
        display: block;
        margin: 0 auto;
    }

    &__title {
        margin: 1.5em 0 1.5em 0;
        text-align: center;
        color: $color-text-white-emphasis-high;
    }

    &__bg {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
    }
}