@import '../../../scss/import';

.odt-Feedback {
    position: relative;
    margin: 36px 0;
    padding: 16px 0;
    border-top-style: solid;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: $color-surface-dark;
    overflow: hidden;
}