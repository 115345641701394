@import '../../../scss/import';

.odt-SearchField {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    &__input {
        display: block;
        width: 100%;
        padding: .65em;
        @include dmSupportRegular;
        font-size: $typo-font-size-body2;
        letter-spacing: .4px;
        border-radius: 0;
        border-bottom: 1px solid $color-text-black-emphasis-high;
        outline: none;

        &:focus {
            border-color: $color-primary;
        }

        &::placeholder {
            color: $color-text-black-emphasis-medium;
            user-select: none;
        }

        &.has-icon {
            padding-left: 24px;
        }
    }

    &__icon,
    &__reset {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        height: 100%;
        font-size: 12px;
        color: $color-text-black-emphasis-medium;
    }

    &__icon {
        left: 0;
    }

    &__reset {
        right: 0;
        outline: none;
        cursor: pointer;

        &:hover,
        &:active {
            color: $color-text-black-emphasis-high;
        }

        // larger click area
        &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 30px;
            height: 30px;
            transform: translate(-50%, -50%);
        }
    }

    &--light {
        .odt-SearchField__icon {
            color: $color-text-white-emphasis-medium;
        }

        .odt-SearchField__input {
            color: $color-text-white-emphasis-high;
            border-color: $color-text-white-emphasis-high;

            &::placeholder {
                color: $color-text-white-emphasis-high;
            }
        }

        .odt-SearchField__reset {
            color: $color-text-white-emphasis-medium;

            &:hover,
            &:active {
                color: $color-text-white-emphasis-high;
            }
        }
    }
}