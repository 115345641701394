@import '../../../scss/import';

.odt-Checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;

    &__inner {
        display: flex;
        align-items: center;
    }

    &__label {
        @include dmSupportRegular;
        margin-left: .8em;
        font-size: $typo-font-size-body2;
        line-height: 1.43;
        letter-spacing: .25px;
        color: $color-text-black-emphasis-high;
    }

    &__native-control {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        opacity: 0;
        cursor: inherit;
    }

    .odt-Checkbox__background {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 20px;
        height: 20px;

        color: white;
        border-radius: 4px;
        border: 2px solid $color-text-black-disabled;

        transition: background-color 100ms ease-in-out;
    }

    .odt-Checkbox__icon {
        opacity: 0;
        transition: opacity 100ms ease-in-out;
    }

    &__native-control:active {
        ~ .odt-Checkbox__background {
            background-color: $color-secondary-50;
            border-color: $color-surface-dark;
        }
    }

    &__native-control:checked {
        ~ .odt-Checkbox__background {
            background-color: $color-secondary;
            border-color: transparent;

            .odt-Checkbox__icon {
                opacity: 1;
            }
        }
    }
}