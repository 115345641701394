@import '../../../scss/import';

$itemHeight: 53px;

.GlossaryListItem {
    display: inline-block;
    width: 100%;
    height: $itemHeight;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    break-inside: avoid; // For browsers without grid support

    &__link {
        display: flex;
        align-items: center;
        height: $itemHeight;

        @include dmSupportRegular;
        font-size: $typo-font-size-body2;
        color: $color-text-black-emphasis-medium;

        &:active {
            color: $color-text-black-emphasis-high;
        }
    }
}