@import '../../../../scss/import';

.odt-ArticleItem {
    width: 100%;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 11px;
    }

    &__title {
        margin: 0;
    }

    &__link {
        color: currentColor;
        text-decoration: none;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &__desc {
        margin: 0;
        hyphens: auto;
        word-wrap: break-word;
    }
}