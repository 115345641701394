@import '../../../scss/import';

.IconSvg {
    &__caption {
        padding-top: .4em;
        @include dmSupportRegular;
        font-size: 12px;
        letter-spacing: .4px;
    }

    &--horizontal {
        .IconSvg__caption {
            display: inline-block;
            margin-left: .6em;
            padding-top: 0;
        }
    }
}