@import '../../../scss/import';

.odt-SearchResultsCount {
    font-size: 12px;
    line-height: 1;
    color: $color-text-black-emphasis-high;
    user-select: none;

    &__text {
        @include dmSupportRegular;
    }
    &__number {
        @include dmBrandBold;
        margin-right: .3em;
        font-variant-numeric: tabular-nums;
    }
}