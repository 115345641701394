@import '../../../../scss/import';

.odt-Tag {
    display: inline-block;
    margin: 0.25em;
    list-style: outside none;
    color: $color-secondary;
    vertical-align: top;
    border-radius: 4px;
    background-color: $color-secondary-10;
    user-select: none;
    overflow: hidden;

    &.is-selectable,
    &.has-link {
        background-color: $color-secondary-10;
    }

    &.is-selected {
        color: $color-text-white-emphasis-high;
        background-color: $color-secondary-200;
    }

    &__inner {
        display: flex;
    }

    &__title,
    &__removeWrapper {
        padding: 9px 14px;
    }

    &__title,
    &__remove {
        margin: 0;
        font-size: $typo-font-size-body2;
        line-height: 1;
        border: 0;
        background: inherit;
        cursor: pointer;
        outline: none;
    }

    &__title {
        @include dmSupportRegular;
        color: currentColor;
        letter-spacing: 0.25px;
        text-decoration: none;
        word-break: break-all;
        hyphens: auto;

        &:hover {
            @media (hover: hover) {
                color: $color-text-white-emphasis-high;
                background-color: $color-secondary-400;
            }
        }

        &.is-disabled {
            cursor: default;
            pointer-events: none;
        }
    }

    &__removeWrapper {
        padding-left: 0;
        line-height: 1;
    }

    &__remove {
        outline: none;
        display: flex;
        font-size: 18px;
        color: $color-secondary;

        &:hover,
        &:focus {
            @media (hover: hover) {
                color: $color-secondary-800;
            }
        }

        &:active {
            color: rgb(83, 83, 83);
        }
    }

    &--outline {
        color: $color-text-black-emphasis-medium;
        background-color: inherit!important;
        border: 1px solid $color-surface-dark;

        .odt-Tag__title {
            &:hover {
                @media (hover: hover) {
                    color: $color-secondary;
                    background-color: $color-secondary-50!important;
                }
            }
        }

        &.is-selected {
            color: $color-secondary;
            border-color: $color-secondary;
        }
    }
}