@import '../../../scss/import';

.odt-RandomArticles {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 60px;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @media screen and (min-width: $breakpoint-desktop) {
        top: 60px;
        bottom: 0;
    }

    &__inner {
        padding-top: 16px;
        padding-bottom: 96px;

        @media screen and (min-width: $breakpoint-desktop) {
            padding-top: 40px;
            padding-bottom: 114px;
        }
    }
}