.toast__columns {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a, button {
        margin-left: 1em;
        padding: 0;
        color: white;
        background-color: transparent;
        border: none;
        appearance: none;
    }

    button {
        flex-basis: 60px;
    }

    a {
        text-decoration: underline;
    }

    .IconSvg svg {
        margin-right: 0;
    }
}

.Toastify__toast-body {
    > div {
        display: flex;
        align-items: center;
    }

    svg {
        font-size: 32px;
        margin-right: 0.4em;
    }
}