@import '../../../scss/import';


.odt-Toolbar {
    position: fixed;
    z-index: 9;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: $color-primary;

    @media screen and (min-width: $breakpoint-desktop) {
        top: 0;
        bottom: auto;
    }

    .odt-Searchbar {
        position: relative;
        top: auto;
        width: 100%;
        height: 60px;

        &__inner {
            padding: 10px 8px 5px 8px;
        }
    }

    .GlossaryNav {
        padding: 0;
        width: 100%;
        height: 60px;
    }


    &--guest {
        .odt-Toolbar__nav {
            @media screen and (min-width: $breakpoint-md) {
                justify-content: space-around;
            }
        }
    }

    .odt-Logo {
        display: none;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        padding-top: 0.2em;
        width: 80px;

        @media screen and (min-width: $breakpoint-ls) {
            display: flex;
            transform: translateX(-260%);
        }

        @media screen and (min-width: $breakpoint-lm) {
            transform: translateX(-360%);
        }
    }

    &__inner {
        position: relative;
    }

    &__nav {
        position: relative;
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: $breakpoint-md) {
            padding: 0 16px;
            justify-content: space-between;
        }
    }

    &__controls {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        overflow: hidden;
        pointer-events: none;
        transition: background-color .3s ease-out;

        &--open {
            pointer-events: auto;
            background-color: rgba(0,0,0,0.5);
        }

        &--closed {
            pointer-events: none;
            background-color: transparent;
        }

        &__slider {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $color-primary;
            transition: transform .3s ease-out;

            &--open {
                transform: none;
            }

            &--closed {
                transform: translateY(100%);

                @media screen and (min-width: $breakpoint-desktop) {
                    transform: translateY(-100%);
                }
            }
        }

        &__inner {
            position: relative;
            padding-left: 50px;
        }

        &__content {
            position: relative;
        }

        &__close {
            position: absolute;
            left: 0;
            top: 0;
            width: 50px;
            height: 100%;
            background-color: darken($color-primary, 8);
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}