@import "import";
@import "fonts";

* {
    box-sizing: border-box;
    outline-color: $color-primary;
    -ms-overflow-style: none;
}

// removes outline on click
// by focusing with the tab-key it's still visible
:focus:not(:focus-visible) {
    outline: none;
}

html {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: $color-surface-light;
    overflow-y: auto;

    &.offcanvas-open,
    &.offcanvas-open > body {
        overflow: hidden!important;
        touch-action: none;
    }
}

html, body {
    margin: 0;
    padding: 0;
}

body {
    display: flex;
    flex: 1 1;
    position: relative;
    @include dmBrandRegular;
    font-size: 1em;
    font-weight: normal;
    line-height: 1.5;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

#odt-root {
    flex: 1 1;
}

a {
    color: $color-secondary;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0; // might come from a global reset
}

/**
* 1. Change the font styles in all browsers.
* 2. Remove the margin in Firefox and Safari.
*/

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: 100%;
    border: 0;
    background: none;
}

/**
* Show the overflow in IE.
* 1. Show the overflow in Edge.
*/

button,
input {
   overflow: visible;
}

/**
* Remove the inheritance of text transform in Edge, Firefox, and IE.
* 1. Remove the inheritance of text transform in Firefox.
*/

button,
select {
    text-transform: none;
}

/**
* Correct the inability to style clickable types in iOS and Safari.
*/

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

input[type="text"],
input[type="search"] {
    appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    appearance: none;
}

input[type="search"]::-ms-clear {
    display: none;
}