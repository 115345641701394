@import '../../../scss/import';

.odt-Headline {
    margin-bottom: .64em;
    color: $color-text-black-emphasis-high;

    &.has-marginTop {
        margin-top: 1em;
    }

    &--h1 {
        @include dmBrandMedium;
        font-size: $typo-font-size-h1;
        line-height: .9;
        letter-spacing: -1.5px;
    }
    &--h5 {
        @include dmBrandRegular;
        font-size: $typo-font-size-h5;
        line-height: 1.2;
        letter-spacing: 0;
    }
    &--h6 {
        @include dmBrandRegular;
        font-size: $typo-font-size-h6;
        line-height: 1.28;
        letter-spacing: .22;
/*
        @media screen and (min-width: $breakpoint-md) {
            font-size: 48px;
        }*/
    }

    &--subtitle1 {
        @include dmBrandBold;
        font-size: $typo-font-size-subtitle1;
        letter-spacing: .15px;
        color: $color-text-black-emphasis-medium
    }

    &--subtitle2 {
        @include dmSupportRegular;
        font-size: $typo-font-size-subtitle2;
        letter-spacing: .1px;
        color: $color-text-black-emphasis-medium
    }

    &--subtitle3 {
        @include dmBrandMedium;
        font-size: $typo-font-size-subtitle3;
        letter-spacing: .4px;
        color: $color-text-black-emphasis-high
    }
}