// Headlines
$typo-font-size-h1: 96px;
$typo-font-size-h2: 65px;
$typo-font-size-h3: 48px;
$typo-font-size-h4: 34px;
$typo-font-size-h5: 26px;
$typo-font-size-h6: 18px;

// Subtitles
$typo-font-size-subtitle1: 16px;
$typo-font-size-subtitle2: 14px;
$typo-font-size-subtitle3: 12px;

// Body
$typo-font-size-body1: 16px;
$typo-font-size-body2: 14px;

// Buttons
$typo-font-size-button: 15px;