@import '../../../scss/import';

.odt-App {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding-top: 60px;

  @media screen and (max-width: $breakpoint-ls - 1) {
    padding-top: 0;
    padding-bottom: 60px;
  }
}