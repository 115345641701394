@import '../../../scss/import';

.ArticleEditor {
    position: relative;
    overflow: auto;
    height: 100%;

    &-content {
        min-height: calc(100vh - 60px);
        background-color: $color-primary-10;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 10px;
    }

    .ArticleActions {
        pointer-events: none;
    }

    .ArticleForm,
    .ArticlePreview,
    .ArticleChangelog {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        transform: translateX(100vw);
        transition: transform 400ms ease-in-out;
    }

    &[data-mode="form"] {
        .ArticleForm {
            position: relative;
            transform: none;
        }
    }

    &[data-mode="preview"] {
        .ArticleForm {
            transform: translateX(-100vw);
        }
        .ArticlePreview {
            position: relative;
            transform: none;
        }
    }

    &[data-mode="changelog"] {
        .ArticleForm,
        .ArticlePreview {
            transform: translateX(-100vw);
        }
        .ArticleChangelog {
            position: relative;
            transform: none;
        }
    }
}