@import '../../../scss/import';

.ArticleDetails {
    position: relative;
    overflow: auto;
    height: 100%;

    &-header {
        margin-bottom: 20px;
    }

    &__title {
        margin: 0;
        hyphens: auto;
    }

    &-content {
        padding: 16px;
        min-height: calc(100vh - 60px);
        background-color: $color-surface-light;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 10px;

        @media screen and (min-width: $breakpoint-md) {
            margin: 0 16px;
            padding: 28px;
        }
    }

    .odt-Button + &__title {
        margin-top: .8em;
    }

    &-tags {
        margin: 36px 0 16px 0;
    }

}

.ArticlePreview-content .ArticleDetails-content {
    min-height: calc(100vh - 159px); // hack
}