.odt-TagListSearchable {
    &__list {
        margin-top: 24px;
    }
}

.odt-TagListSearchableLight {
    position: relative;
    flex: 1 1 auto;

    &__results {
        position: relative;
        padding-top: 20px;
        height: 100%;
        width: 100%;
        background-color: white;
    }

    .odt-SearchResultsCount {
        margin-bottom: 12px;
    }

    .odt-TagList-wrapper {
        height: 100%;
        max-height: 300px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        //box-shadow: inset 0 -25px 50px -35px rgba(0, 0, 0, 1)
    }
}