@import '../../../scss/import';

.odt-Searchbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: $color-primary;
    z-index: 8;

    &--disabled {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        padding: 5px 8px 24px 8px;

        @media screen and (min-width: $breakpoint-md) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &__field {
        display: flex;
        align-items: center;
        position: relative;
        margin-right: 18px;
        width: 100%;
    }

    &__filter {
        color: $color-text-white-emphasis-high;
        cursor: pointer;
        user-select: none;

    }
}