.odt-SearchResultsHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    > *:first-child {
        margin-top: 0;
        padding-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}