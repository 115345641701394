@import '../../../scss/import';

.odt-ArticleListMasonry {
    &__rowContent {
        padding-bottom: 1em;

        .odt-Card__inner {
            opacity: .2;
            transition: opacity 50ms ease-in-out;
        }

        &.is-visible {
            .odt-Card__inner {
                opacity: 1;
            }
        }
    }
}

.ReactVirtualized__List,
.ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible!important;
}