.odt-Filter {
    position: relative;
    display: flex;
    flex: 1 1 auto;

    &__search {
        display: block;
        width: 100%;
        padding: .45em;
        border-bottom: 1px solid black
    }

    .odt-TagListSearchableLight__result {
        padding-bottom: 50px;
    }
}