@import '../../../scss/import';

.odt-FavorizeButton {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0;
    color: $color-text-black-emphasis-medium!important;
    border: 0;
    background: none;
    appearance: none;
    cursor: pointer;
    outline: none; // should be more accessible

    &:hover:not(.is-favored) {
        @media (hover: hover) {
            color: $color-text-black-emphasis-high!important;
        }
    }

    // bigger click area
    &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 30px;
        height: 30px;
        transform: translate(-50%, -50%);
    }

    &.is-favored {
        color: $color-secondary!important;

        &:hover {
            @media (hover: hover) {
                color: $color-secondary-600!important;
            }
        }
    }

    &[disabled] {
        color: $color-text-black-disabled!important;
        pointer-events: none;
    }

    &.is-loading {
        animation: pulse 800ms infinite ease-in-out;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.3);
    }
}