@import '../../../scss/import';

.ArticleActions {
    display: flex;
    align-items: center;
    margin-top: 16px;

    &__item {
        display: inline-block;
        flex-shrink: 0;
        text-decoration: none;
        font-size: 20px;
        line-height: 0;
        color: $color-text-black-emphasis-medium;
        cursor: pointer;
        outline: none;

        &:hover,
        &:focus,
        &:active {
            @media (hover: hover) {
                color: $color-text-black-emphasis-high;
            }
        }

        &:not(:last-child) {
            margin-right: 1.35em;
        }
    }
}