@import '../../../scss/import';

.odt-ToolbarItem {
    flex: 1;

    @media screen and (min-width: $breakpoint-md) {
        flex: none;
    }

    &__link {
        position: relative;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0;
        font-size: 24px;
        color: $color-text-white-emphasis-medium;
        text-decoration: none;

        &:active {
            color: white;

            @media screen and (min-width: $breakpoint-md) {
                background-color: transparent;
            }
        }

        &.active {
            color: $color-text-white-emphasis-high;
        }
    }
}