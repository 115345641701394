.odt-TagListSearchable {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;

    &__header {
        display: flex;
        overflow: hidden;
    }

    &__body {
        flex: 1 0 auto;
    }

    &__footer {
        flex: 0 1 auto;
        margin-top: auto;
        padding-top: 1em;
        width: 100%;
        text-align: center;
    }
}