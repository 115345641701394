$appMidGrey: #cccccc;
$appDarkGrey: #9c9c9c;
$appLightBlue: #61baef;
$appDarkBlue: #1a80c1;
$appGreen: #00c696;
$appRed: #ff6666;

// Primary
$color-primary: #0037FF;
$color-primary-900: #0000CD;
$color-primary-800: #0005DC;
$color-primary-700: #001EE6;
$color-primary-600: #002DF3;
$color-primary-500: $color-primary;
$color-primary-400: #485AFF;
$color-primary-300: #707BFF;
$color-primary-200: #9EA1FF;
$color-primary-100: #C7C6FF;
$color-primary-50: #E9E8FF;
$color-primary-10: #F2F5F7;

// Secondary
$color-secondary: #C44D83;
$color-secondary-900: #5F264A;
$color-secondary-800: #822F59;
$color-secondary-700: #983261;
$color-secondary-600: #AE3569;
$color-secondary-500: #BE386F;
$color-secondary-400: $color-secondary;
$color-secondary-300: #CC6898;
$color-secondary-200: #D990B5;
$color-secondary-100: #E6BBD2;
$color-secondary-50: #F4E4ED;
$color-secondary-10: rgb(250, 242, 246);

// surface
$color-surface-light: #FFFFFF;
$color-surface-dark: rgb(218, 218, 218);

// text dark
$color-text-black-emphasis-high: rgba(0, 0, 0, .87);
$color-text-black-emphasis-medium: rgba(0, 0, 0, .60);
$color-text-black-disabled: rgba(0, 0, 0, .38);

// text light
$color-text-white-emphasis-high: rgba(255, 255, 255, 1);
$color-text-white-emphasis-medium: rgba(255, 255, 255, .60);
$color-text-white-disabled: rgba(255, 255, 255, .38);