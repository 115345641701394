@import '../../../scss/import';

.odt-Offcanvas {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;

    &.is-open {
        pointer-events: auto;

        .odt-Offcanvas__body {
            box-shadow:
                0 16px 24px 2px rgba(0, 0, 0, 0.14),
                0 6px 30px 5px rgba(0, 0, 0, 0.12);
            transform: translateX(0);
        }
        .odt-Offcanvas__overlay {
            opacity: 1;
        }
    }

    &__overlay {
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.55);
        opacity: 0;
        transition: opacity 250ms ease-in-out;
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding: 20px;
        z-index: 1;
        height: 100%;
        width: 100%;
        max-width: 500px;
        background-color: white;
        box-shadow: none;
        overflow-y: scroll;
        touch-action: auto;

        transform: translateX(100%);
        transition: transform 200ms ease-in-out;

        @media screen and (min-width: $breakpoint-md) {
            padding: 64px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        flex: 0 0 auto;
        margin-bottom: 64px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }
}