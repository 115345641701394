@import '../../../scss/import';

.odt-GlossaryNav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    overflow: hidden;

    &--disabled {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
    }

    &__content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
        bottom: 0;
        overflow: hidden;
        overflow-x: auto;
        display: flex;
        align-items: center;
        -webkit-overflow-scrolling: touch;
    }

    &__slider {
        display: inline-block;
        white-space: nowrap;
        padding: 0 30px 0 10px;
        margin: 0 auto;
    }

    &__item {
        display: inline-block;

        &:not(:last-child) {
            margin-right: .55em;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 36px;
            height: 36px;

            @include dmBrandMedium;
            font-size: 20px;
            vertical-align: middle;
            text-align: center;
            text-decoration: none;
            color: $color-text-white-emphasis-medium;
            line-height: 36px;

            border-radius: 100%;

            user-select: none;

            &:active {
                background-color: $color-primary-800;
            }
        }

        &--active {
            a {
                color: $color-text-white-emphasis-high;
                background-color: $color-primary-800;
            }
        }
    }

}