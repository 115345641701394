.odt-LoadingIndicator {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    background-color: #4b37f8;
    border-radius: 100%;
    animation: sk-scaleout 1.0s infinite ease-in-out;
    align-self: center;
}

@keyframes sk-scaleout {
    0% { 
        transform: scale(0);
    } 
    100% {
        transform: scale(1.0);
        opacity: 0;
    }
}