@import '../../../scss/import';

.odt-TagList-wrapper {
    width: 100%;

    &.has-padding {
        padding: 1em 0;
    }
}

.odt-TagList {
    display: inline-block;
    margin: -.25em;
    padding: 0;
    list-style: none;

    &--horizontal {
        white-space: nowrap;
        overflow-x: scroll;
        max-width: 100%;
    }

    &--flatList {
        display: flex;
        flex-direction: column;
        margin: 0;

        .odt-Tag {
            display: block;
            margin: 0;
            border-radius: 0;
            background-color: transparent;

            &:not(:last-child) {
                border-bottom: 1px solid #e6e6e6;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
            }

            &.is-selected {
                .odt-Tag__title {
                    background-color: $color-secondary-10;
                }
            }
        }

        .odt-Tag__title {
            flex: 1 0 auto;
            padding: 1.4em .8em;
            color: $color-text-black-emphasis-medium;
            text-align: left;

            &:active {
                background-color: $color-secondary-10;
            }
            &:hover,
            &:focus {
                background-color: transparent;
            }
        }
    }
}