@import '../../../scss/import';

.Form {
    &-footer {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 2px solid $color-surface-dark;
    }

    &-field {
        margin-bottom: 20px;

        &.error {
            .Form-control {
                border-color: $appRed;
            }
        }
    }

    &-control {
        display: block;
        margin: 0;
        padding: 12px;
        width: 100%;
        min-height: 40px;

        @include dmBrandRegular;
        font-size: $typo-font-size-body1;
        line-height: 1.6;

        background-color: $color-surface-light;

        box-shadow: none;
        appearance: none;
        resize: none;
    }

    &-buttons {
        padding: 16px 0 16px 0;

        .Button {
            margin: 0;

            &-submit {
                float: right;
            }
        }
    }

}