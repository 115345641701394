$spacing: .5;

.odt-ButtonGroup {
    display: flex;
    flex-wrap: nowrap;

    &--horizontal {
        margin: 0 ($spacing * -1em);

        & > * {
            margin: 0 ($spacing * 1em);
        }

        &.odt-ButtonGroup--justify {
            justify-content: space-between;
        }

        &.odt-ButtonGroup--center {
            justify-content: center;
        }
    }

    &--vertically {
        flex-direction: column;

        & > *:not(:last-child) {
            margin-bottom: ($spacing * 1em);
        }

        &.odt-ButtonGroup--center {
            align-items: center;
        }
    }
}