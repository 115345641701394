@import '../../../scss/import';

.ArticleChangelog {
    &__content {
        padding: 16px;
        background-color: $color-surface-light;
    }

    &__message {
        margin-top: 24px;
    }
}