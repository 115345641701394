// dmBrand
@mixin dmBrandBold() {
    font-family: "dmbrand-bold", sans-serif;
    font-weight: bold;
}

@mixin dmBrandMedium() {
    font-family: "dmbrand-medium", sans-serif;
    font-weight: 500;
}

@mixin dmBrandRegular() {
    font-family: "dmbrand-regular", sans-serif;
    font-weight: normal;
}

@mixin dmBrandLight() {
    font-family: "dmbrand-light", sans-serif;
    font-weight: 300;
}

// dmSupport
@mixin dmSupportBold() {
    font-family: "dmsupport-bold", sans-serif;
    font-weight: bold;
}

@mixin dmSupportMedium() {
    font-family: "dmsupport-medium", sans-serif;
    font-weight: 500;
}

@mixin dmSupportRegular() {
    font-family: "dmsupport-regular", sans-serif;
    font-weight: normal;
}

@mixin dmSupportItalic() {
    font-family: "dmsupport-italic", sans-serif;
    font-style: italic;
    font-weight: normal;
}

@mixin dmSupportLight() {
    font-family: "dmsupport-light", sans-serif;
    font-weight: 300;
}
