@import '../../../scss/variables/colors';

.odt-Card {
    $parent: &;

    display: block;
    position: relative;
    z-index: 0;
    background-color: $color-surface-light;
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.10);
    //transition: background-color 50ms ease-in-out;

    &.is-clickable {
        &:active {
            background-color: $color-surface-dark;
        }
    }


    &__inner {
        padding: 16px;
    }

    &--big {
        #{$parent}__inner {
            padding: 25px;
        }
    }
}