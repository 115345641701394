@import '../../../scss/import';

.odt-Button {
    display: inline-block;
    padding: .8em 1.4em;
    min-width: 80px;
    max-width: 100%;
    outline: medium none;

    border: 1px solid transparent;
    border-radius: 2em;

    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;

    transition: all 150ms ease-in-out;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;

        @include dmBrandMedium;
        font-size: $typo-font-size-button;
        line-height: 1;
        text-align: center;
        text-decoration: none;
        letter-spacing: 1.25px;
        white-space: nowrap;
    }

    &__icon {
        display: flex;
        align-items: center;
        margin-right: .8em;
    }

    &--text {
        padding-right: 0;
        padding-left: 0;
        min-width: unset;
        color: $color-secondary;
        border-radius: 0;
        background-color: transparent;

        &:hover,
        &:active,
        &.is-selected,
        &.is-disabled {
            background-color: transparent;
        }
    }

    &--link {
        text-decoration: underline;
        border-radius: 0;

        > * {
            font-size: 12px;
            line-height: 1.25;
            letter-spacing: 0.4px;
        }
    }

    &--primary {
        color: $color-text-white-emphasis-high;
        background-color: $color-primary;

        &:hover {
            @media (hover: hover) {
                background-color: $color-primary-800;
            }
        }

        &:active,
        &.is-selected {
            background-color: $color-primary-300;
        }

        &.is-disabled {
            background-color: $color-surface-dark;
        }
    }

    &--secondary {
        color: $color-text-white-emphasis-high;
        background-color: $color-secondary;

        &:hover {
            @media (hover: hover) {
                background-color: $color-secondary-800;
            }
        }

        &:active,
        &.is-selected {
            background-color: $color-secondary-300;
        }

        &.is-disabled {
            background-color: $color-surface-dark;
        }
    }

    &--outline {
        color: $color-secondary;
        border-style: solid;
        border-color: $color-secondary;
        background-color: transparent;

        &:hover,
        &:active {
            @media (hover: hover) {
                border-color: $color-secondary-200;
            }
        }

        &:hover {
            @media (hover: hover) {
                background-color: $color-secondary-10;
            }
        }

        &:active,
        &.is-selected {
            background-color: $color-secondary-50;
        }

        &.is-selected {
            border-color: $color-secondary-200;
        }

        &.is-disabled {
            border-color: $color-surface-dark;
        }
    }

    &--light {
        color: $color-text-white-emphasis-high;
        border-color: $color-text-white-emphasis-high;

        &:focus,
        &:hover,
        &:active {
            border-color: $color-text-white-emphasis-high;
            background-color: transparent;
        }
    }

    &--chip {
        @include dmSupportRegular;
        color: $color-text-black-emphasis-medium;
        border: 1px solid $color-surface-dark;
        border-radius: 4px;

        &:hover {
            @media (hover: hover) {
                color: $color-secondary;
                border-color: transparent;
                background-color: $color-secondary-10!important;
            }
        }

        &.is-selected {
            color: $color-secondary;
            border-color: $color-secondary;
        }
    }

    &--floating {
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 2em;
        left: 50%;
        z-index: 9;
        transform: translateX(-50%);
        box-shadow: 0 2px 12px rgba(0,0,0,0.25);

        @media screen and (max-width: $breakpoint-desktop - 1){
            margin-bottom: 60px;
        }
    }

    &--noPadding {
        padding: 0;
        background: none;

        &:hover,
        &:active,
        &.is-seleted {
            background: none;
        }
    }

    &.is-disabled {
        color: $color-text-black-disabled;
        cursor: not-allowed;
    }

    &.has-failed {
        color: white;
        background-color: red;
    }
}