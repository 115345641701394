@import '../../../scss/import';

.odt-ArticleRouter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    bottom: 60px;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 1;

    @media screen and (min-width: $breakpoint-ls) {
        top: 60px;
        bottom: 0;
    }
}