.odt-Background {
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    min-height: 100vh;

    &__image {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        //animation-name: fadeIn;
        //animation-duration: 300ms;
        //animation-timing-function: ease-in-out;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}